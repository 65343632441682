.dark{
  --card-text-color: var(--black);
  --card-bg-color: var(--ice);
}

.light{
  --card-text-color: var(--black);
  --card-bg-color: var(--white);
}

.container {
  --size-card: 424px;
  display: flex;
  flex-flow: column wrap;
  border-radius: var(--radius-l);
  transition: border 0.3s ease-in-out;
  height: var(--size-card);
}

.image{
  position: relative;
  height: var(--size-card);
  border-radius: var(--radius-l);
}

.image img{
  border-radius: var(--radius-l);
}

.icon{
  --size:28px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: var(--spacing-24);
  right: var(--spacing-24);
  border-radius: 9rem;
  background: rgb(191 194 196 / 25%);
  transition: background-color 0.3s ease-in-out;
  height: var(--size);
  width: var(--size);
}
.icon svg{
  --size: 20px;
  height: var(--size);
  width: var(--size);
  color: var(--white);
}

.hoverOverlay{
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  gap: var(--spacing-8);
  font: var(--font-h3-desktop-default);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: var(--radius-l);
  background: rgb(0 0 0 / 15%);
  backdrop-filter: blur(4px);
  transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.container:hover .hoverOverlay{
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

@media (max-width: 1024px){
  .container{
    --size-card: 320px;
  }
}